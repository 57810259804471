import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`We are a small, but passionate, indie game development team based in Baltimore.`}</p>
    <p>{`We were founded in 2021 by two brothers with years of experience in web and game development. We LOVE video games and hope to make some games that you'll love too.`}</p>
    <p>{`And no, we didn't typo "Paralysis". It's a play on the co-founder's last name, Perales!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      